.videoBG {
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  transform: scaleX(-1);
}

@media (min-aspect-ratio: 16/9) {
  .videoBG {
    width: 100vw;
    height: auto;
  }
}
@media (max-width: 768px) {
  .videoBG {
    width: auto;
    height: 100vh;
    left: -50%;
  }
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
}
