.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  background-color: none;
  background: none;
}

.home-section > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #ffffff;
  margin-left: 10%;
}

.home-section > .content > .title {
  font-family: Sansation;
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  animation: fadeIn 1s ease-in-out;
}

.home-section > .content > .text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  margin-top: 20px;
  animation: slideInUp 1s ease-in-out;
}

@media (max-width: 768px) {
  .home-section > .content {
    padding: 0 10%;
    margin: unset;
  }

  .home-section > .content > .title {
    font-size: 60px;
  }

  .home-section > .content > .text {
    font-size: 20px;
    line-height: 30px;
  }
}
