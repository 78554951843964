.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 80px;
  width: calc(100% - 80px);
  background: #ffffff;
  text-align: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.header.visible {
  animation: showHeader 0.3s forwards;
}

.header.hidden {
  animation: hideHeader 0.3s forwards;
}

@keyframes hideHeader {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes showHeader {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Sansation;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1979b6;
  text-decoration: none;
  margin: 0 20px;
  cursor: pointer;
}

.links a,
.links div {
  font-family: Sansation;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #1979b6;
  text-decoration: none;
  margin-left: 20px;
  cursor: pointer;
}

.menu-btns {
  display: none;
  height: 2rem;
}

.menu-btns > img {
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 10px;
}

@media (max-width: 768px) {
  .header {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .links {
    display: none;
    font-family: Sansation;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    color: #1979b6;
    text-decoration: none;
    margin: 30px 0;
    width: 100%;
  }

  .menu-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .links.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    /* padding-top: 20%; */
    /* height: 100vh; */
    background: #ffffff;
    z-index: 1000;
    animation: showLinks 0.3s forwards;
    padding: unset;
    margin: unset;
  }

  @keyframes showLinks {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes hideLinks {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  .links a {
    font-family: Sansation;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    color: #1979b6;
    text-decoration: none;
    margin: 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
