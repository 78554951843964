.App {
  height: 100vh;
  width: 100vw;
}

.pdf-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 80px);
  margin-top: 80px;
  border: none;
}

.how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background-color: #f9f9f9;
  padding: 5% 10%;
}

.how-it-works > .title {
  font-family: Sansation;
  font-size: 55px;
  font-weight: 700;
  text-align: left;
  color: #1979b6;
}

.how-it-works .text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: #35343a;
  margin-top: 20px;
}

.how-it-works > .workflow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  animation: fadeIn 1s ease-in-out;
}

.how-it-works > .workflow .step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  font-family: Sansation;
  font-size: 32px;
  font-weight: 100;
  line-height: 42px;
  text-align: center;
  background-color: #1979b6;
  color: #ffffff;
  border-radius: 15px;
  width: 15%;
  animation: slideInUp 1s ease-in-out;
}

.how-it-works > .workflow .last-step {
  width: calc(15% + 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.how-it-works > .workflow .last-step > .step {
  width: calc(100% - 40px);
}

.how-it-works > .workflow .last-step > .return-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.how-it-works > .workflow .arrow {
  margin: 0 20px;
  padding-top: 50px;
}
@media (max-width: 768px) {
  .how-it-works > .workflow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .how-it-works > .workflow .step {
    width: 50%;
    margin: unset;
  }

  .how-it-works > .workflow .last-step > .step {
    width: 50%;
  }

  .how-it-works > .workflow > .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    rotate: 90deg;
    margin: unset;
    width: 100%;
    padding: unset;
    height: 80px;
  }

  .how-it-works > .workflow > .arrow > img {
    width: 50px;
    height: 50px;
  }

  .how-it-works > .workflow .last-step {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: -10%;
  }

  .how-it-works > .workflow .last-step > .return-arrow {
    width: 10%;
    rotate: 90deg;
    height: 70px;
  }

  .how-it-works > .workflow .last-step > .return-arrow > img {
    height: 100%;
  }

  .how-it-works > .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    width: 100%;
    margin-top: 30px;
  }

  .how-it-works .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 100%;
  }
}

.process-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 15px 0;
  margin-top: 50px;
  width: 100%;
  flex-wrap: wrap;
}

.process-steps > .step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 20px);
  margin-bottom: 20px;
}

.process-steps > .step > .sub-title {
  font-family: Sansation;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  text-align: left;
  color: #1979b6;
}

.process-steps > .step > .text {
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #35343a;
  margin-top: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .process-steps {
    margin-top: 20px;
  }

  .process-steps > .step {
    width: 100%;
  }
}

.images-section {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.images-section > .image {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
}

.images-section > .image > img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.images-section > .image:nth-child(2n) {
  flex-direction: row-reverse;
}

.images-section > .image > .text {
  width: 50%;
  height: 100%;
  background-color: #eff9ffa6;
  color: #1979b6;
  font-family: Sansation;
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .images-section > .image {
    height: 50vh;
    flex-direction: column;
  }

  .images-section > .image:nth-child(2n) {
    flex-direction: column;
  }

  .images-section > .image > .text {
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    height: 50%;
  }

  .images-section > .image > img {
    width: 100%;
    height: 50%;
  }
}

.learn-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  padding: 5% 0;
}

.learn-more > .symbol {
  width: 60px;
  height: 60px;
}

.learn-more > .symbol > img {
  width: 100%;
  height: 100%;
}

.learn-more > .title {
  font-family: Sansation;
  font-size: 55px;
  font-weight: 700;
  line-height: 150px;
  text-align: center;
  color: #1979b6;
}

.learn-more > .text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  padding: 0 15%;
}

.learn-more > .open-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-radius: 15px;
  background-color: #1979b6;
  font-family: Sansation;
  font-size: 30px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .learn-more {
    padding: 50px 0;
  }

  .learn-more > .title {
    font-size: 40px;
    line-height: 100px;
  }

  .learn-more > .text {
    font-size: 20px;
    line-height: 30px;
    padding: 0 10%;
  }

  .learn-more > .open-btn {
    font-size: 20px;
    padding: 10px 20px;
  }
}

.ai-empowered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #1979b6;
}

.ai-empowered > .text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 41px;
  text-align: center;
  color: #ffffff;
  margin: 50px 0;
  width: 50%;
}

.ai-empowered > .stripe {
  width: 100%;
}

.ai-empowered > .stripe > img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .ai-empowered > .text {
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    margin: 25px 0;
    width: 80%;
  }

  .ai-empowered > .stripe {
    height: 150px;
  }
  .ai-empowered > .stripe > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.key-strength {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
}

.key-strength > .title {
  color: #1979b6;
  text-align: center;
  font-family: Sansation;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin: 70px 0;
}

.key-strength > .strengths {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;
}

.key-strength > .strengths > .strength {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  padding: 20px;
}

.key-strength > .strengths > .strength > .title {
  color: #1979b6;
  font-family: Sansation;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  padding: 5px 0;
  border-bottom: 1px solid #35343a;
  width: 100%;
}

.key-strength > .strengths > .strength > .title > img {
  margin-right: 10px;
}

.key-strength > .strengths > .strength > .text {
  color: #35343a;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .key-strength > .title {
    font-size: 30px;
    margin: 10% 15%;
  }

  .key-strength > .strengths {
    width: 100%;
  }

  .key-strength > .strengths > .strength {
    width: 100%;
    padding: 5% 10%;
  }
}

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-image: url("./assets/waves.svg");
  background-color: #ffffff;
  padding: 5% 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact-us > .title {
  color: #1979b6;
  text-align: center;
  font-family: Sansation;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.contact-us > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-us > .buttons > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #1979b6;
  background: #1979b6;
  padding: 15px 30px;
  margin: 0 10px;
}

.contact-us > .buttons > button > img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.contact-us > .buttons > button > a {
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Sansation;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.2px; /* 126% */
  text-decoration: none;
}

.contact-us > .early-access {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  height: 60px;
}

.contact-us > .early-access > input {
  height: 100%;
  width: 40%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0) inset;
  outline: none;
  border: none;
  padding: 0 20px;
  overflow: hidden;
  color: #1979b6;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.contact-us > .early-access > input::placeholder {
  color: #1979b6;
}

.contact-us > .early-access > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #1979b6;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  border: none;
  outline: none;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .contact-us > .title {
    font-size: 40px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .contact-us > .early-access {
    margin-top: 50px;
    flex-direction: column;
    height: 120px;
  }

  .contact-us > .early-access > button {
    width: 80%;
    margin-left: unset;
    margin-top: 10px;
  }

  .contact-us > .early-access > input {
    width: calc(80% - 40px);
  }

  .contact-us > .buttons {
    flex-direction: column;
    width: 80%;
  }

  .contact-us > .buttons > button {
    margin: 10px 0;
    width: 100%;
  }
}

.location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #35343a;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  background-color: #d8d8d8;
  padding: 10px 0;
}
